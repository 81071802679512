import axios from 'axios';
import { API_URL } from '@/utils/constants';
import { Pageable } from '@/models/pageable.type';
import { PaginationParams } from '@/models/pagination-params.type';
import { StatusesDto, StatusCommentDto, StatusCommentFormDto, StringInfo, SearchStatusesDto, ReturnedInfo } from '@/models/api/statuses-dto.interface';

export const getStatuses = async (
  params: PaginationParams,
): Promise<Pageable<StatusesDto>> => {
  const response = await axios
    .get(API_URL + 'statuses', { params })
    .then((response) => ({
      data: {
        data: response.data.content,
        total: response.data.totalElements,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};

export const findStatus = async (data: string): Promise<SearchStatusesDto[]> => {
  return axios.post(API_URL + 'statuses', null, {
    params: {
      orderId: data
    }
  }).then((response) => response.data);
};

export const getStatusComments = async (data: string): Promise<StatusCommentDto[]> => {
  return axios.get(API_URL + 'statuses/getComment', {
    params: {
      orderId: data
    }
  }).then((response) => response.data);
};


export const addStatusComment = async (data: StatusCommentFormDto): Promise<void> => {
  return axios.post(API_URL + 'statuses/addComment',  data).then((response) => response.data);
};


export const getStatusProducts = async (data: string): Promise<StringInfo[]> => {
  return axios.get(API_URL + 'statuses/stringsInfo', {
    params: {
      orderId: data
    }
  }).then((response) => response.data.stringsInfo);
};

export const getStatusProductsReturned = async (data: string): Promise<ReturnedInfo[]> => {
  return axios.get(API_URL + 'statuses/stringsInfo', {
    params: {
      orderId: data
    }
  }).then((response) => response.data.returnedStringsInfo);
};
