<template>
  <ui-header>
    Приемка Сортировочного Центра
    <template #right>{{`${store.currentPath?.pointAbv}${store.currentPath?.partnerPointUid ? ` (${store.currentPath?.partnerPointUid})` : ''}` }}</template>
  </ui-header>
  <ui-main style="margin-bottom: 24px;" v-loading="isLoading">
    <el-form ref="formRef" :model="form" @submit.prevent="pickUp()">
      <el-form-item style="margin-bottom: 0px;" prop="orderId">
        <el-input ref="orderIdInput" style="width: 100%; max-width: 100%;" clearable placeholder="Введите номер заказа" size="large"
          class="search-input" v-model="form.orderId" />
        <div class="flex-box">
          <div>
            <el-button size="large" @click="pickUp()" type="primary" :disabled="isLoading" :loading="isLoading"
              :style="'min-width: 116px'">
              Принять
            </el-button>
            <!-- <el-popover :visible="showConfirm" placement="bottom" :width="260">
              <p>Пакет уже существует, добавить еще один?</p>
              <div style="text-align: right; margin: 0">
                <el-button size="small" text @click="showConfirm = false">Нет</el-button>
                <el-button size="small" type="primary" @click="pickNew()">Да</el-button>
              </div>
              <template #reference>
                <div></div>
              </template>
</el-popover> -->
          </div>
        </div>
      </el-form-item>
    </el-form>
  </ui-main>
  <ui-main v-loading="isLoading">
    <el-form ref="formRef" :model="searchForm" label-position="top" @submit.prevent="searchOrder()">
      <el-form-item :prefix-icon="Search" style="margin-bottom: 16px;" prop="orderId">
        <el-input size="large" placeholder="Поиск заказа" :prefix-icon="Search" class="search-input" clearable
          v-model="searchForm.orderId" @input="searchOrder()" @clear="clearForm()" />
      </el-form-item>
    </el-form>

    <div class="items-center-column">
      <el-table :data="elements.data" :border="true" empty-text="Нет данных" table-layout="auto"
        @sort-change="sortChange">
        <el-table-column sortable="custom" prop="orderId" label="Номер Заказа" />
        <el-table-column sortable="custom" prop="quantity" label="Мест" />
        <el-table-column sortable="custom" prop="created" label="Дата создания">
          <template #default="{ row }">
            {{ formatDate(row.created) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="statusName" label="Статус Заказа" />
        <el-table-column sortable="custom" prop="statusModtime" label="Дата статуса">
          <template #default="{ row }">
            {{ formatDate(row.statusModtime) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="pointAbv" label="ПВЗ Назначения" />
      </el-table>
      <ui-pagination v-if="elements.total > elements.data.length && !isSearch" :total="elements.total"
        :current-page="page" @change="setPage" />
    </div>
  </ui-main>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';

import { getSortingCenter, updateOrderStatusFromSorting } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { useGetElements } from '@/composables/use-get-elements';
import { PickupLocationDto } from "@/models/api/order-pickup-dto.interface";
import { dateFormat } from '@/utils/date-format';
import { useUserStore } from '@/store/use-user-store';
import { DEFAULT_PARAMS } from '@/utils/constants';
import { SortingCenterDto } from '@/models/api/sorting-center-dto.inreface';
import { ElNotification } from 'element-plus';
import { Search } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'sorting-center',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
  },
  setup() {

    onMounted(() => {
      orderIdInput.value.focus()
    })

    const form = ref({
      orderId: '',
    });


    const timer = ref<any>(null);
    const orderIdInput = ref<any>(null);

    const { isLoading, page, setPage, elements, getElements, sortChange } =
      useGetElements<SortingCenterDto>(getSortingCenter);

    const formatDate = (date: string) => dateFormat(date);

    const isSearch = ref(false)

    const extractId = (str: string) => {
      const match = str.substring(5);

      if (match) {
        return match.split('-')[0];
      }
      return ''
    };

    const pickUp = async () => {
      const orderId = form.value.orderId;
      await updateOrderStatusFromSorting(orderId).then((data) => {
        getElements();
        ElNotification({ position: 'bottom-right', type: 'success', message: data });
        form.value.orderId = '';
        orderIdInput.value.focus()
      })
    }

    const store = useUserStore()

    const searchForm = ref({
      orderId: '',
    });

    const searchOrder = () => {
      clearTimeout(timer.value);
      isSearch.value = true;
      timer.value = setTimeout(() => {
        getElements({
          orderIdFilter: searchForm.value.orderId,
          ...DEFAULT_PARAMS
        })
      }, 500);
    }

    const clearForm = () => {
      isSearch.value = false
      getElements();
      searchForm.value.orderId = ''
    }


    watch(()=> searchForm.value, ()=> {
      if (searchForm.value.orderId.length === 0){
        clearForm()
      }
    })


    return {
      isLoading,
      elements,
      page,
      setPage,
      form,
      pickUp,
      getElements,
      store,
      formatDate,
      searchForm,
      searchOrder,
      sortChange,
      isSearch,
      clearForm,
      Search,
      orderIdInput
    };
  },
});
</script>

<style lang="stylus" scoped>

</style>
