import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "items-center-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_header = _resolveComponent("ui-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_order_point = _resolveComponent("order-point")!
  const _component_show_phone = _resolveComponent("show-phone")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ui_pagination = _resolveComponent("ui-pagination")!
  const _component_ui_main = _resolveComponent("ui-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_header, null, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" Маршрутизация распределительного центра ")
      ])),
      _: 1
    }),
    _withDirectives((_openBlock(), _createBlock(_component_ui_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "formRef",
          model: _ctx.searchForm,
          "label-position": "top",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.searchOrder()), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              "prefix-icon": _ctx.Search,
              style: {"margin-bottom":"16px"},
              prop: "orderId"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  ref: "searchInput",
                  size: "large",
                  placeholder: "Поиск заказа",
                  "prefix-icon": _ctx.Search,
                  class: "search-input",
                  clearable: "",
                  modelValue: _ctx.searchForm.orderId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchForm.orderId) = $event)),
                  onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchOrder())),
                  onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearForm()))
                }, null, 8, ["prefix-icon", "modelValue"])
              ]),
              _: 1
            }, 8, ["prefix-icon"])
          ]),
          _: 1
        }, 8, ["model"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_table, {
            data: _ctx.elements.data,
            border: true,
            "empty-text": "Нет данных",
            "table-layout": "auto",
            onSortChange: _ctx.sortChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "orderId",
                label: "Номер заказа"
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "created",
                label: "Дата создания"
              }, {
                default: _withCtx(({ row }) => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(row.created)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "statusName",
                label: "Статус"
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "statusModtime",
                label: "Дата статуса"
              }, {
                default: _withCtx(({ row }) => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(row.statusModtime)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "pointAddress",
                label: "Адрес ПВЗ"
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "pointAbv",
                label: "Шифр ПВЗ"
              }, {
                default: _withCtx(({ row }) => [
                  _createVNode(_component_order_point, {
                    onChangeValue: _ctx.changePickpoint,
                    orderId: row.orderId,
                    pointAbv: row.pointAbv
                  }, null, 8, ["onChangeValue", "orderId", "pointAbv"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "Телефон",
                prop: "more"
              }, {
                default: _withCtx(({ row }) => [
                  _createVNode(_component_show_phone, {
                    orderId: row.orderId
                  }, null, 8, ["orderId"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data", "onSortChange"]),
          (_ctx.elements.total > _ctx.elements.data.length && !_ctx.isSearch)
            ? (_openBlock(), _createBlock(_component_ui_pagination, {
                key: 0,
                total: _ctx.elements.total,
                "current-page": _ctx.page,
                onChange: _ctx.setPage
              }, null, 8, ["total", "current-page", "onChange"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.isLoading]
    ])
  ], 64))
}